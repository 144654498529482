<template id="authSetingDiaLog">
  <el-dialog
          :visible.sync="dialogShow"
          width="480px"
          height="640px"
          :before-close="close"
          @open="open">
    <div class="fontWeight fontSize" style="text-align: center">权限设置</div>
    <el-form ref="form" :model="form" label-width="200px" :rules="rules" style="max-height: 60vh;overflow: scroll">
      <el-form-item label="名称：" >
        {{item.name}}
      </el-form-item>
      <el-form-item label="部门：">
        {{ item.departmentName }}
      </el-form-item>
      <el-form-item label="角色：" prop="role">
        <el-radio-group v-model="form.role" @change="roleChanged">
          <el-radio label="1">用户</el-radio>
          <el-radio label="2">管理员</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="访问统计权限：" prop="statistics" >
        <el-checkbox v-model="form.statistics" :disabled="disabled" @change="checkboxChange('statistics',$event)"></el-checkbox>
      </el-form-item>
      <el-form-item label="访问模块控制权限：" prop="isModel">
        <el-checkbox v-model="form.isModel" :disabled="disabled" @change="checkboxChange('isModel',$event)"></el-checkbox>
      </el-form-item>
      <el-form-item label="模块权限：" prop="model">
        <div v-for="(item,index) in modelList">
          <el-checkbox v-model="form.model" :label="item.id">{{ item.modularName }}</el-checkbox>
        </div>
      </el-form-item>
    </el-form>
    <div style="display: flex; justify-content: center; align-items: center;">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="confirm" v-loading="loading">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {getReportModelList,addModularUserRel,
    addModularUserRelForControl,
    updateModularUserRel,updateModularUserRelForControl,
    getModularUserRel,getModularUserRelForControl,getWxReportModelList} from "@/api/api";
  export default {
    name: "authSettingDiaLog",
    props: {
      dialogShow: {
        type: Boolean,
        default: false,
        required: true
      },
      orgId: {
        required: true
      },
      item: {
        required: false
      }
    },
    data() {
      return {
        modelList: [],
        loading: false,
        disabled:true,
        form: {
          role: "",
          statistics: false,
          isModel: false,
          model: []
        },
        rules: {
          role: [
            {required: true, message: '请选择角色', trigger: 'change'}]
        },
        userInfo:{}

      }
    },
    created() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.userInfo = userInfo
    },
    methods: {
      roleChanged(val){
        this.form.role = val
        if(val == 1){
          this.disabled = true
          this.form.isModel = false
          this.form.statistics = false
        }else{
          this.disabled = false
          this.form.statistics = true
          this.form.isModel = true
        }
      },
      checkboxChange(type,val){
        if(this.form.role == 2){
          this.form.statistics = true
          this.form.isModel = true
          return
        }
        if(type == 'statistics'){
          this.form.statistics = val
        }else{
          this.form.isModel = val
        }
      },
      async open() {
        this.form.role = String(this.item.roleId == 0 ? "" : this.item.roleId)
        if(this.form.role == 2){
          this.disabled = false
        }
        this.form.isModel = this.item.totalFlag ? true : false
        this.form.statistics = this.item.modularFlag ? true : false
        if(window.location.pathname == '/controlJH' && !this.$store.state.isWxWork){
          await this.getModulesList()
        }else{
          await this.getWxModulesList()
        }
        await this.getModularUserRel()
      },
      async getModularUserRel(){
        const body ={
          userId:String(this.item.userId),
          orgId:this.orgId
        }
        let data = ""
        if(this.$store.state.isWxWork){
          data = await getModularUserRel(body)
        }else{
          data = await getModularUserRelForControl(body)
        }
        const res = JSON.parse(data)
        if(res != 0 || res.length > 0){
          const arr = []
          res.forEach(item =>{
            arr.push(Number(item.modularId))
          })
          this.form.model =  Array.from(new Set(arr))
        }
      },
      async getWxModulesList() {
        try{
          let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
          const body = {
            orgId: Number(userInfo.orgId),
          }
          const res = await getWxReportModelList(body)
          const data = JSON.parse(res)
          this.modelList = data
        }catch (e) {
          console.log(e)
        }

      },
      async getModulesList() {
        const body = {
          orgId: this.orgId,
        }
        const res = await getReportModelList(body)
        const data = JSON.parse(res)
        this.modelList = data.dataList
      },
      async updateModularUserRelFun(){
        let body
        if(this.userInfo && this.$store.state.isWxWork){
          body = {
            modularFlag : this.form.isModel ? 1 : 0,
            roleId :this.form.role,
            totalFlag:this.form.statistics ? 1 : 0,
            userIdNew:this.userInfo.userId
          }
        }else{
          body = {
            modularFlag : this.form.isModel ? 1 : 0,
            roleId :this.form.role,
            totalFlag:this.form.statistics ? 1 : 0
          }
        }

        if(this.$store.state.isWxWork){
          const data1 = await updateModularUserRel(body,this.item.id)
        }else{
          const data1 = await updateModularUserRelForControl(body,this.item.id)
        }
        this.item.roleId = body.roleId
        this.item.totalFlag = body.totalFlag
        this.item.modularFlag = body.modularFlag
        this.$emit('update:item',this.item)
      },
      async addModularUserRelFun(){
        if(!this.item.id || !this.orgId){
          return
        }
        const body ={
          modularIds:this.form.model.join(','),
          userId:String(this.item.userId),
          orgId:Number(this.orgId),
        }
        if(this.$store.state.isWxWork){
          const data = await addModularUserRel(body)
        }else{
          const data = await addModularUserRelForControl(body)
        }

      },
      close() {
        this.form = {
          name: "",
          department: "",
          role: "1",
          statistics: false,
          isModel: false,
          model: []
        }
        this.$emit("update:item", {})
        this.$emit("update:dialogShow", false)
      },
      confirm() {
        if (this.loading) {
          return
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            if(this.loading){
              return
            }
            this.loading = true
            this.addModularUserRelFun()
            this.updateModularUserRelFun()
            this.loading = false
            this.$emit("update:dialogShow", false)
          }
        })
      }
    }
  }
</script>

<style scoped>
</style>
